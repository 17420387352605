.gx-main-container-labels-print {
  box-sizing: border-box;
  padding: 0;
}
.gx-labels-container {
  border: 1px solid #000;
  margin: auto;
  position: relative;
}

.gx-box-left {
  position: absolute;
  background-color: #fff;
  border-right: 1px solid #000;
  width: 16px;
  height: 100%;
  font-size: 11px;
  color: #000;
  writing-mode: vertical-rl;
  text-align: center;
  left: 0;
}
.gx-box-right {
  background-color: #fff;
  border-left: 1px solid #000;
  position: absolute;
  right: 0;
  width: 16px;
  height: 100%;
  font-size: 11px;
  color: #000;
  writing-mode: vertical-lr;
  text-align: center;
}

.gx-box-top {
  text-align: center;
  line-height: 16px;
  font-size: 11px;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #000;
  position: absolute;
  height: 16px;
  width: 100%;
  top: 0;
}
.gx-box-bottom {
  text-align: center;
  line-height: 16px;
  font-size: 11px;
  color: #000;
  background-color: #fff;
  border-top: 1px solid #000;
  position: absolute;
  height: 16px;
  width: 100%;
  bottom: 0;
}
.gx-left-transform-text {
  position: absolute;
  transform: rotate(180deg);
  background: red;
  height: 100%;
  width: 100%;
}
.gx-box-label {
  display: grid;
  grid-template-columns: 22% auto;
  width: 100%;
  padding: 16px;
  margin: 0;
}
.gx-item-labels-container {
  margin: 0;
}
.gx-item-labels-container .gx-titles {
  list-style: none;
  text-align: right;
  padding: 0;
  margin: 0;
}
.gx-item-labels-container .gx-titles li {
  color: #000;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 11px;
}
.gx-item-labels-container .gx-values {
  list-style: none;
  text-align: left;
  margin-left: 8px;
  padding: 0;
}
.gx-item-labels-container .gx-values li {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 11px;
}

.gx-labels-qr-code {
  position: absolute;
  bottom: 65px;
  right: 20px;
}
.gx-text-grid-3 {
  display: grid;
  grid-template-columns: 50% 10% 30% 10%;
}
.gx-text-grid-3 span:last-child {
  text-align: center;
}
