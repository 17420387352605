.mobile-plan-card {
  width: 100%;
  border-radius: 8px;
}

.mobile-plan-card-header {
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

.mobile-plan-card-body {
  border-left: 1px solid #eceff1;
  border-right: 1px solid #eceff1;
}

.mobile-plan-card-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px;
}

.mobile-card-item {
  padding: 15px;
}

.mobile-item-avatar-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #E0A848;
}

.mobile-item-avatar-icon i {
  font-size: 20px;
}

.mobile-item-title {
  font-size: 20px;
  line-height: 26px;
  margin-left: 20px;
  color: #757575;
}

.mobile-items-concept-title {
  font-size: 14px;
  line-height: 18px;
}

.mobile-item-content {
  padding: 5px 15px 15px 15px;
  color: #757575;
}

.mobile-item-content i, .mobile-icon-item {
  width: 20px;
  display: flex;
  justify-content: center;
}