.carousel .slide .legend {
  height: auto;
  opacity: 1;
  bottom: 10px;
  background-color: transparent !important;
}

.active-nav-link {
  background-color: rgb(194, 110, 45, 0.1);
  border-radius: 8px;
}

.active-nav-link button {
  font-weight: bold;
}

.heading-link-color {
  color: #925627 !important;
}

.inactive-nav-link {
  border-bottom: 4px solid transparent !important;
}

/* .carousel .slider-wrapper {
  background-image: url("../../assets/images/banner/c.png");
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
} */

.carousel .slide {
  background-color: transparent;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  min-height: 200px;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

@media only screen and (min-width: 300px) and (max-width: 377px) {
  .carousel .slider-wrapper {
    min-height: 25vh;
  }
  .carousel .slide .legend {
    top: 50px;
    /* bottom: 55px; */
  }
  .carousel .slide .legend .legend-title {
    font-size: 1rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 0.7rem;
    width: 250px;
  }
}

@media only screen and (min-width: 378px) and (max-width: 450px) {
  .carousel .slider-wrapper {
    min-height: 20vh;
  }
  .carousel .slide .legend {
    /* bottom: 40px; */
    top: 50px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 1.2rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 0.8rem;
    width: 280px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 575px) {
  .carousel .slider-wrapper {
    min-height: 30vh;
  }
  .carousel .slide .legend {
    /* bottom: 10px; */
    top: 50px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 1.5rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1rem;
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 599px) {
  .carousel .slider-wrapper {
    min-height: 40vh;
  }
  .carousel .slide .legend {
    bottom: -40px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 1.5rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1rem;
    width: 400px;
  }
  .carousel .slide .legend .legend-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {
  .carousel .slider-wrapper {
    min-height: 40vh;
  }
  .carousel .slide .legend {
    /* bottom: -60px; */
    top: 50px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 1.8rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1.2rem;
    width: 500px;
  }
  .carousel .slide .legend .legend-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 715px) {
  .carousel .slide .legend {
    /* bottom: -70px; */
    top: 30px;
  }
}

@media only screen and (min-width: 720px) {
  .carousel .slide .legend {
    top: 30px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1000px) {
  .carousel .slider-wrapper {
    min-height: 41vh;
  }
  .carousel .slide .legend {
    /* bottom: -100px; */
    top: 100px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 2.2rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1.4rem;
    width: 100%;
  }
  .carousel .slide .legend .legend-subtitle .legend-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 920px) {
  .carousel .slide .legend {
    bottom: -120px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .carousel .slider-wrapper {
    min-height: 50vh;
  }
  .carousel .slide .legend {
    /* bottom: -170px; */
    top: 130px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 2.8rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1.6rem;
    width: 100%;
  }
  .carousel .slide .legend .legend-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1201px) {
  .carousel .slider-wrapper {
    min-height: 73vh;
  }
  .carousel .slide .legend {
    margin-right: 0%;
    width: 101.5%;
    height: 390px;
    top: 110px;
    left: 44%;
  }

  .carousel .slide .legend .legend-title {
    font-size: 3rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 1.8rem;
    width: 100%;
  }
  .carousel .slide .legend .legend-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .carousel .slider-wrapper {
    min-height: 64vh;
  }
  .carousel .slide .legend {
    /* bottom: -250px; */
    top: 100px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 3.2rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 2rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .carousel .slider-wrapper {
    min-height: 40vh;
  }
  .carousel .slide .legend {
    /* bottom: -300px; */
    top: 100px;
  }
  .carousel .slide .legend .legend-title {
    font-size: 4rem;
  }
  .carousel .slide .legend .legend-subtitle {
    font-size: 2rem;
    width: 100%;
  }
  .carousel .slide .legend .legend-subtitle .legend-buttons {
    margin-top: 20px;
  }
}

#container-radar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 180px;
  right: 25px;
}

#thumb {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: 60px 60px;
  background-position: center;
  z-index: 2;
}

.ant-carousel .slick-slide {
  background: transparent !important;
}

#thumb button {
  width: 60px;
  height: 60px;
}

#thumb button i {
  margin-top: 0.5rem;
}

#thumb img {
  width: 30px;
  height: 30px;
}

.circle1,
.circle2 {
  position: absolute;
  border: 1px solid #c26e2d;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #c26e2d;
}

.circle1 {
  animation: circ1 3s infinite;
}

.circle2 {
  animation: circ2 3s infinite;
  animation-delay: 1.5s;
}

@keyframes circ1 {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(5);
    opacity: 0;
  }
}

@keyframes circ2 {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(5);
    opacity: 0;
  }
}

/* BANNERS */
.section-bubble,
.section-bubble1,
.section-bubble2,
.section-bubble3,
.section-bubble4,
.section-bubble_type-a,
.section-bubble_type-b {
  /*margin-bottom: 20vw;*/
  position: relative;
}
.section-bubble:after,
.section-bubble1:after,
.section-bubble2:after,
.section-bubble3:after,
.section-bubble4:after,
.section-bubble_type-a:after,
.section-bubble_type-b:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20vw;
  background: green;
  background-size: 100%;
  transform: translate(0, 100%);
}

.section-bubble1 {
  /*background-image: url("../../assets/images/banner/3804520.jpg");*/
  /*max-height: 100vh!important;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;*/
}
.section-bubble1:after {
  /*background-color: #FFD2B0;*/
  background-color: transparent;
  bottom: -1px;
  transform: rotate(180deg) !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fafafa' viewBox='0 0 1185 248'><circle cx='76' cy='121.1' r='20' class='a'/><circle cx='870' cy='201.1' r='11' class='a'/><circle cx='814.5' cy='165.6' r='24.5' class='a'/><path d='M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z' class='a'/></svg>");
}

.section-bubble2 {
  background-color: #ffd2b0;
}
.section-bubble2:after {
  background-color: #384364;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1185 248'><path d='M50.5 199.8c112.4 0 87.5-49 188.7-59.3s146.7 38.3 219.5 38.3 107.9-21.9 210.8-69.3c102.8-47.3 274-8.7 424.8 69.3 37.7 19.5 68 36.7 90.7 51.5V0H0v193C15 197.2 31.6 199.8 50.5 199.8zM1109 106.9c11 0 20 9 20 20 0 11-9 20-20 20s-20-9-20-20C1089 115.9 1098 106.9 1109 106.9zM370.5 57.9c13.5 0 24.5 11 24.5 24.5 0 13.5-11 24.5-24.5 24.5S346 95.9 346 82.4C346 68.9 357 57.9 370.5 57.9zM315 35.9c6.1 0 11 4.9 11 11s-4.9 11-11 11 -11-4.9-11-11S308.9 35.9 315 35.9z' fill='%23FFD2B0'/></svg>");
}

.section-bubble3 {
  background-color: #384364;
}
.section-bubble3:after {
  background-color: #cccccc;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23384364' viewBox='0 0 1185 248'><circle cx='76' cy='121.1' r='20' class='a'/><circle cx='870' cy='201.1' r='11' class='a'/><circle cx='814.5' cy='165.6' r='24.5' class='a'/><path d='M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z' class='a'/></svg>");
}
.section-bubble3 h1 {
  color: white;
}
.section-bubble3 p {
  color: white;
  opacity: 0.8;
}

.section-bubble4 {
  background-color: #cccccc;
}
.section-bubble4:after {
  background-color: #333333;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1185 248'><path d='M50.5 199.8c112.4 0 87.5-49 188.7-59.3s146.7 38.3 219.5 38.3 107.9-21.9 210.8-69.3c102.8-47.3 274-8.7 424.8 69.3 37.7 19.5 68 36.7 90.7 51.5V0H0v193C15 197.2 31.6 199.8 50.5 199.8zM1109 106.9c11 0 20 9 20 20 0 11-9 20-20 20s-20-9-20-20C1089 115.9 1098 106.9 1109 106.9zM370.5 57.9c13.5 0 24.5 11 24.5 24.5 0 13.5-11 24.5-24.5 24.5S346 95.9 346 82.4C346 68.9 357 57.9 370.5 57.9zM315 35.9c6.1 0 11 4.9 11 11s-4.9 11-11 11 -11-4.9-11-11S308.9 35.9 315 35.9z' fill='%23cccccc'/></svg>");
}

.section-bubble5 {
  background: #333333;
  color: white;
  text-align: center;
}

.section-bubble_type-a:after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='0 0 1185 248'><circle cx='76' cy='121.1' r='20' class='a'/><circle cx='870' cy='201.1' r='11' class='a'/><circle cx='814.5' cy='165.6' r='24.5' class='a'/><path d='M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z' class='a'/></svg>");
}

.section-bubble_type-b:after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1185 248'><path d='M50.5 199.8c112.4 0 87.5-49 188.7-59.3s146.7 38.3 219.5 38.3 107.9-21.9 210.8-69.3c102.8-47.3 274-8.7 424.8 69.3 37.7 19.5 68 36.7 90.7 51.5V0H0v193C15 197.2 31.6 199.8 50.5 199.8zM1109 106.9c11 0 20 9 20 20 0 11-9 20-20 20s-20-9-20-20C1089 115.9 1098 106.9 1109 106.9zM370.5 57.9c13.5 0 24.5 11 24.5 24.5 0 13.5-11 24.5-24.5 24.5S346 95.9 346 82.4C346 68.9 357 57.9 370.5 57.9zM315 35.9c6.1 0 11 4.9 11 11s-4.9 11-11 11 -11-4.9-11-11S308.9 35.9 315 35.9z' fill='red'/></svg>");
}

.bg-contact-us {
  position: absolute;
  height: 60vh !important;
}

@media only screen and (min-width: 1380px) {
  .bg-contact-us {
    height: 70vh !important;
  }
}

@media only screen and (max-width: 1160px) {
  .bg-contact-us {
    height: 50vh !important;
  }
}

@media only screen and (max-width: 991px) {
  .bg-contact-us {
    display: none !important;
  }
}

.active-mobile-link {
  background-color: rgb(194, 110, 45, 0.1);
  padding: 6px;
  border-top: 3px solid #c26e2d !important;
}
.ant-layout,
.copy {
  color: #000000;
  font-weight: bold;
}

.ant-layout .cont-footer {
  align-items: start !important;
}

.ant-layout .eCar-mensaje {
  width: 20%;
}

/*FOOTER*/
.medio-contacto {
  margin-top: 9.3% !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
}
.content-social {
  width: 45%;
}
.mensaje {
  margin-top: 5%;
  color: #000000;
}
.ant-form-horizontal .ant-row {
  margin: 0 !important;
}
.ant-layout .login-form-button {
  margin-left: 63%;
}
.ant-col {
  width: 100%;
}
@media (min-width: 320px) {
  .banner-principal {
    margin-top: 70px;
    width: 100%;
    height: 495px;
    font-size: 16px;
  }
  .gx-text-left.primer-banner {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 200px;
    background-image: url("../../assets/images/banner/banner-logo1.jpg");
    background-size: cover;
    background-position: center;
  }
  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    width: 78%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }

  .primer-text .titulo-eCarpintero {
    font-size: 25px;
  }
  .segundo-text .titulo-eCarpintero {
    font-size: 25px;
  }
  .tercer-text .titulo-eCarpintero {
    font-size: 25px;
  }

  .gx-text-left.segundo-banner {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-image: url("../../assets/images/banner/banner-logo2.png");
    background-size: cover;
    background-position: center;
  }
  .gx-text-left.tercer-banner {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
    background-image: url("../../assets/images/banner/banner-logo3.png");
    background-size: cover;
    background-position: center;
  }
  .arrow-btn {
    display: none !important;
  }
  .arrow-iz {
    display: none;
  }
  .arrow-de {
    display: none;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
    height: 198px;
  }
}
@media (min-width: 425px) {
  .banner-principal {
    font-size: 20px;
  }
  .gx-text-left.primer-banner,
  .gx-text-left.segundo-banner,
  .gx-text-left.tercer-banner {
    min-height: 240px;
  }
  .primer-text .titulo-eCarpintero {
    font-size: 32px;
  }
  .segundo-text .titulo-eCarpintero {
    font-size: 32px;
  }
  .tercer-text .titulo-eCarpintero {
    font-size: 32px;
  }

  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    position: absolute;
    top: 29%;
  }
}
@media (min-width: 580px) {
  .banner-principal {
    position: relative;
    margin-top: 40px;
    font-size: 20px;
  }
  .gx-text-left.primer-banner,
  .gx-text-left.segundo-banner,
  .gx-text-left.tercer-banner {
    min-height: 300px;
  }
  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start !important;
    position: absolute;
    top: 35%;
    margin-left: 0;
    text-align: center;
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  .primer-text .titulo-eCarpintero {
    font-size: 37px;
  }
  .segundo-text .titulo-eCarpintero {
    font-size: 37px;
  }
  .tercer-text .titulo-eCarpintero {
    font-size: 37px;
  }

  .arrow-btn {
    display: inline-block !important;
    height: 15% !important;
  }
  .arrow-iz {
    display: inline-block;
    position: absolute !important;
    right: 88.3%;
    top: 34%;
    width: 10%;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: 1px solid black !important;
  }
  .arrow-de {
    display: inline-block;
    position: absolute !important;
    left: 90%;
    top: 34%;
    width: 10%;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: 1px solid black !important;
  }
  .arrow-btn:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}
@media (min-width: 751px) {
  .banner-principal {
    margin-top: 25px;
    font-size: 20px;
  }
  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    margin-right: 24%;
    width: 50%;
    top: 36%;
  }
  .primer-text .titulo-eCarpintero {
    font-size: 35px;
  }
  .segundo-text .titulo-eCarpintero {
    font-size: 35px;
  }
  .tercer-text .titulo-eCarpintero {
    font-size: 35px;
  }
  .arrow-iz {
    position: absolute !important;
    right: 89%;
    top: 34%;
  }
  .arrow-de {
    position: absolute !important;
    left: 90%;
    top: 34%;
  }
}

@media (min-width: 1024px) {
  .banner-principal {
    margin-top: 5px;
    width: 100%;
    min-height: 300px;
    position: relative;
    font-size: 25px;
  }
  .gx-text-left.primer-banner,
  .gx-text-left.segundo-banner,
  .gx-text-left.tercer-banner {
    min-height: 330px;
  }
  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    width: 52%;
    top: 39%;
  }
  .primer-text .titulo-eCarpintero {
    letter-spacing: 3.5px;
    font-size: 50px;
  }
  .segundo-text .titulo-eCarpintero {
    letter-spacing: 3.5px;
    font-size: 50px;
  }
  .tercer-text .titulo-eCarpintero {
    letter-spacing: 3.5px;
    font-size: 50px;
  }

  .arrow-btn {
    height: 18% !important;
  }
  .arrow-iz {
    width: 4%;
    position: absolute !important;
    right: 95.3%;
    top: 37%;
  }
  .arrow-de {
    width: 4%;
    position: absolute !important;
    left: 96%;
    top: 37%;
  }
  .arrow-btn:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
    height: 368px !important;
    bottom: 0;
  }
}
@media (min-width: 1201px) {
  .banner-principal {
    height: 495px;
    margin-top: -10px;
    font-size: 30px;
  }
  .gx-text-left.primer-banner,
  .gx-text-left.segundo-banner,
  .gx-text-left.tercer-banner {
    min-height: 424px;
  }

  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    width: 50%;
    top: 33%;
  }
  .primer-text .titulo-eCarpintero {
    font-size: 60px;
  }
  .segundo-text .titulo-eCarpintero {
    font-size: 60px;
  }
  .tercer-text .titulo-eCarpintero {
    font-size: 60px;
  }

  .arrow-iz {
    width: 4%;
    position: absolute !important;
    right: 95.3%;
    top: 30%;
  }
  .arrow-de {
    width: 4%;
    position: absolute !important;
    left: 96%;
    top: 32%;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 400px !important;
  }
  .carousel .slider-wrapper {
    min-height: 55vh;
  }
}

@media (min-width: 1366px) {
  .primer-text,
  .segundo-text,
  .tercer-text {
    text-align: start;
    width: 45%;
    top: 36.5%;
  }
  .arrow-iz {
    width: 4%;
    top: 33%;
  }
  .arrow-de {
    width: 4%;
    top: 33%;
  }
}
