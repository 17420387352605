.skeleton-avatar {
  background-color: #ddd !important;
  background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229, 229, 229, 0.8) 40px, #F4F4F4 80px) !important;
  background-size: 600px !important;
  animation: shine-avatar 1s infinite ease-out !important;
}

@keyframes shine-avatar {
  0% {
    background-position: -90px;
  }
  40%, 100% {
    background-position: 90px;
  }
}
