.plans-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  color: #000000;
}

.plans-table tr td:first-child {
  border-left: 1px solid #eceff1;
}

.plans-table tr td {
  border-right: 1px solid #eceff1;
  border-bottom: 1px solid #eceff1;
  padding: 20px;
}

.plans-table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.plans-table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.plans-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.plans-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.header-cell {
  vertical-align: top;
  font-size: 20px;
  font-weight: normal;
  padding: 20px;
}

.header-cell-features {
  width: 55% !important;
  background-color: #f5f7f8;
  border: 1px solid #eceff1;
}

.header-cell-basic,
.footer-cell-basic {
  width: 15% !important;
  background-color: #039be5 !important;
  border: 1px solid #039be5 !important;
  color: #ffffff !important;
  text-align: center !important;
  vertical-align: top !important;
}

.header-cell-standard,
.footer-cell-standard {
  width: 15% !important;
  border: 1px solid #2e3a4d !important;
  background-color: #2e3a4d !important;
  color: #ffffff !important;
  text-align: center !important;
  vertical-align: top !important;
}

.header-cell-advanced,
.footer-cell-advanced {
  width: 15% !important;
  background-color: #c26e2d !important;
  border: 1px solid #c26e2d !important;
  color: #ffffff !important;
  text-align: center !important;
  vertical-align: top !important;
}

.plans-table-tbody {
  border-right: 1px solid #eceff1;
  border-right-width: 2px;
}

.plans-table-tbody tr {
  border: 1px solid #eceff1;
}

.plans-table-tbody tr td:not(:first-child) {
  text-align: center;
  color: #757575;
}

.plans-table-tbody tr td {
  position: relative;
  vertical-align: middle;
  font-size: 1.2rem;
}

.body-cell-features {
  font-weight: normal;
  font-size: 1.2rem;
  margin: 0;
}

.body-cell-features ul,
.body-cell-with-ul ul {
  list-style: none;
  padding: 0;
}

.body-cell-features ul li {
  color: #757575 !important;
}

.body-cell-features ul li,
.body-cell-with-ul ul li {
  font-size: 1.2rem;
  line-height: 26px;
  margin: 12px 0;
}

.body-cell-with-ul li i {
  font-size: 1.2rem !important;
}

.body-cell-with-ul ul {
  margin-top: 36px;
}

.plans-card {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eceff1;
  margin: 0 auto 20px;
  max-width: 1280px;
  overflow: hidden;
}

.page-title {
  max-width: 1280px;
  margin: 40px auto;
  color: #fff;
  padding-left: 40px;
  font-weight: 600;
  font-size: 38px;
}

@media only screen and (max-width: 992px) {
  .title-erp {
    text-align: center;
    margin-left: 1.5% !important;
  }
  .subtitle-a {
    font-size: 18px !important;
  }
  .page-title {
    font-size: 24px !important;
    margin: 20px auto !important;
  }
  .subtitle {
    font-size: 20px !important;
    margin-top: 4px !important;
  }
}

.subtitle {
  margin-top: 6px;
  font-size: 28px;
  opacity: 0.9;
  font-weight: lighter;
}

.subtitle-a {
  margin-bottom: 12px;
  font-size: 24px;
  opacity: 0.9;
  font-weight: lighter;
}

.plans-card-content {
  padding: 40px;
}

@media only screen and (max-width: 992px) {
  .plans-card-content {
    padding: 15px;
  }
}

.action-plan-text {
  font-size: 1.6rem !important;
}

.contact-button {
  margin-top: 15px;
  background-color: #039be5;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  padding: 10px 20px;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  color: #039be5;
  text-decoration: none !important;
}

.contact-button:hover {
  background-color: rgba(255, 255, 255, 0.85);
}

.font-legend-plans {
  font-size: 14px !important;
}

.legend-plans {
  justify-items: center;
}

.legend-plans > div {
  width: 30px;
  height: 4px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.include {
  width: 85px;
}

.include > div {
  background: #52c41a !important;
}

.optional {
  width: 200px !important;
}

.optional > div {
  background: #fa8c16 !important;
}

.not-include {
  width: 100px !important;
}

.not-include > div {
  background: #f44336 !important;
}

.illustration {
  position: absolute;
  width: 350px;
  margin-top: -235px;
}

@media only screen and (min-width: 991px) and (max-width: 1400px) {
  .content-erp {
    margin: 0 auto;
    margin-top: 3px;
    width: 90% !important;
  }
  .illustration {
    right: 5% !important;
  }
  #clipPolygon {
    clip-path: ellipse(80% 68% at 44% 2%) !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .illustration {
    right: 10% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .illustration {
    right: 15% !important;
  }
}

.hovered-list-item:hover {
  background: #e8e8e84f !important;
}

/*---------------------------------------*/

@media (min-width: 320px) {
  .banner-erp {
    margin-top: 105px;
  }
}
/*BANNER*/
@media (min-width: 580px) {
  .banner-erp {
    margin-top: 50px;
  }
}
@media (min-width: 1024px) {
  .banner-erp {
    margin-top: 0;
  }
}

.content-erp {
  margin: 0 auto;
  margin-top: 3px;
  width: 68%;
}
.title-erp {
  color: "#000000" !important;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 2%;
  margin-left: 20.5%;
}

.ant-layout,
.banner-erp {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  height: 190px;
  object-fit: cover;
  background-image: url("../../assets/images/banner/banner-erp.png");
}
.banner-erp .titulo-erp {
  align-self: center;
  color: #ffffff;
  font-size: 30px;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
  margin-top: -9px;
}
