.dropzone {
  padding: 20px;
  border: 1px dashed #e8e8e8;
  border-radius: 6px;
}
.gallery-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
}
.gallery-wrapper > figure {
  display: flex;
  flex: 1 1 auto;
  width: 50%;
  margin-bottom: 0;
}
.gallery-wrapper figure img {
  transition: .5s ease;
  backface-visibility: hidden;
}
.gallery-wrapper > figure > img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border: 1px solid #ffffff;
}
.gallery-wrapper figure:hover > img {
  opacity: 0.8;
}
.gallery-selected > img {
  opacity: 0.3;
}
.gallery-selected {
  background-color: rgb(184, 38, 38) !important;
}
.gallery-wrapper figure:hover > .gallery-actions, .gallery-selected > .gallery-actions {
  opacity: 1;
}
.gallery-actions {
  display: flex;
  transition: .5s ease;
  opacity: 1;
  margin-top: 15px;
  margin-left: 15px;
  position: absolute;
  text-align: center;
}
